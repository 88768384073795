export default [
  {
    name: 'DUAL_RECORD',
    text: 'Dual record',
    short: 'DR'
  },
  {
    name: 'MATCH_WIN_RATIO',
    text: 'Match win ratio',
    short: 'MWR'
  },
  {
    name: 'HEAD_2_HEAD',
    text: 'Head to head',
    short: 'H2H'
  },
  {
    name: 'SET_WIN_RATIO',
    text: 'Set win ratio',
    short: 'SWR'
  },
  {
    name: 'POINTS_BETWEEN_3',
    text: 'Points amoung tied teams (three or more only)',
    short: 'PATT 3+'
  },
  {
    name: 'POINTS_BETWEEN',
    text: 'Points amoung tied teams',
    short: 'PATT'
  },
  {
    name: 'OVERALL_POINTS',
    text: 'Overall points',
    short: 'OP'
  },
  {
    name: 'ORIGINAL_SEED',
    text: 'Original seed',
    short: 'OS'
  },
  {
    name: 'POINTS_FOR',
    text: 'Total Points For',
    short: 'PF'
  }
]
