import * as StatusEnum from '../classes/TournamentStatus'
import LogoUrls from '../classes/LogoUrls'
import { distance } from '../classes/HelperFunctions'
import moment from 'moment'

export default class TournamentSummary {
  id = 0
  startDate = moment()
  endDate = moment()
  name = null
  locations = []
  organization = {
    id: 0,
    name: null,
    username: null
  }

  theme = {
    logoUrl: null
  }

  isPublic = false
  sanctionedBy = null
  statusId = 0
  _logos = []
  noOrg = false
  rawLogos = []
  dates = []

  constructor (dto) {
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
    this.startDate = moment(dto.startDate)
    this.endDate = moment(dto.endDate)
  }

  // getters
  get nameAndDate () {
    return `${this.name} - ${this.startDate.format('MMM YYYY')}`
  }

  get idNameAndDate () {
    return `${this.id} - ${this.nameAndDate}`
  }

  get timestamp () {
    return this.startDate.format('X')
  }

  get unix () {
    return this.startDate.unix()
  }

  get numOfDays () {
    return this.endDate.diff(this.startDate, 'days')
  }

  get sameMonth () {
    return this.endDate.format('M') === this.startDate.format('M')
  }

  get dateStatus () {
    if (this.dates && this.dates.length) {
      if (this.dates.includes(moment().format('YYYY-MM-DD'))) return StatusEnum.INPROCESS
    }
    if (!this.startDate) return StatusEnum.UPCOMING
    if (this.startDate.isAfter()) return StatusEnum.UPCOMING
    if (moment().isBetween(this.startDate, this.endDate, 'd', '[]')) {
      if (this.isTournament) return StatusEnum.INPROCESS
      if (this.numOfDays > 2) {
        const nextDay = moment(this.startDate.format()).add(1, 'd')
        return moment().isBetween(this.startDate, nextDay, 'd', '[]') ? StatusEnum.INPROCESS : StatusEnum.ONGOING
      }
      return StatusEnum.INPROCESS
    }
    return StatusEnum.PAST
  }

  get locationsString () {
    return this.locations.join(', ')
  }

  get regOpen () {
    return this.dateStatus === StatusEnum.UPCOMING
  }

  get orgLogo () {
    const u = this.organization && this.organization.username
    if (u) {
      const l = LogoUrls[u.toLowerCase()]
      if (l) {
        l.raw = 'org'
        return [l]
      }
      return this.theme && this.theme.logoUrl ? [{ url: this.theme.logoUrl, alt: this.organization.username, raw: 'org', style: [207, 198].includes(this.organization.id) ? `background-color: ${this.theme.color1}` : '' }] : []
    }
    return []
  }

  get logos () {
    const noOrg = this.noOrg || (this.organization && this.organization.username && this.organization.username.toLowerCase() === this.subdomain.toLowerCase() && this._logos.length)
    var l = noOrg ? this._logos : this.orgLogo.concat(this._logos)
    return l ? l.filter(f => f && f.url) : null
  }

  set logos (val) {
    this.rawLogos = val
    if (!val) {
      this._logos = val
    } else {
      this.noOrg = val.includes('noOrg')
      this._logos = val.filter(f => typeof f !== 'string' || (f !== 'noOrg' && f.toLowerCase() !== this.subdomain.toLowerCase())).map(m => {
        if (typeof m === 'string') {
          const logo = LogoUrls[m.toLowerCase()]
          if (logo) {
            logo.raw = m
          } else {
            console.log(m)
          }
          return logo
        }
        return m
      })
    }
  }

  get subdomain () {
    if (window.location.host.includes('p1440.')) return 'p1440'
    if (window.location.host.includes('teqsports.')) return 'teqball'
    const parts = window.location.host.split('.')
    return parts.length > 1 && parts[0].toLowerCase() !== 'volleyballlife' ? parts[0] : ''
  }

  get sBody () {
    if (this.tags) {
      return this.tags.includes('AAU') ? 'AAU' : this.tags.includes('BVCA') ? 'BVCA' : this.tags.includes('BVNE') ? 'BVNE' : this.tags.includes('JVA') ? 'JVA' : this.tags.includes('p1440') ? 'p1440' : this.tags.includes('USAV') ? 'USAV' : this.tags.filter(f => f.startsWith('AVP')).length > 0 ? 'AVP' : 'Unsanctioned'
    }
    return 'Unsanctioned'
  }

  get allSanctionBodies () {
    const a = []
    if (this.tags) {
      if (this.tags.includes('AAU')) a.push('AAU')
      if (this.tags.includes('BVCA')) a.push('BVCA')
      if (this.tags.includes('BVNE')) a.push('BVNE')
      if (this.tags.includes('JVA')) a.push('JVA')
      if (this.tags.includes('p1440')) a.push('p1440')
      if (this.tags.includes('USAV')) a.push('USAV')
      if (this.tags.filter(f => f.startsWith('AVP')).length > 0) {
        a.push('AVPA')
        a.push('AVP')
      }
    }
    if (a.length === 0) {
      a.push('Unsanctioned')
    }
    return a
  }

  get isShowcase () {
    return this.tags && this.tags.includes('Showcase')
  }

  get isTournament () {
    return this.tags && this.tags.includes('Tournament')
  }

  get isClinic () {
    return this.tags && this.tags.includes('Clinic')
  }

  get isCamp () {
    return this.tags && this.tags.includes('Camp')
  }

  get isTryout () {
    return this.tags && this.tags.includes('Tryout')
  }

  get isLeague () {
    return this.tags && this.tags.includes('League')
  }

  get isSeries () {
    return this.tags && this.tags.includes('Series')
  }

  get isYouthProgram () {
    return this.tags && this.tags.includes('Youth Program')
  }

  get isEvaluationDay () {
    return this.tags && this.tags.includes('Evaluation Day')
  }

  get isTrainingSession () {
    return this.tags && this.tags.includes('Training Session')
  }

  get isPrivateLesson () {
    return this.tags && this.tags.includes('Private Lesson')
  }

  get eventType () {
    return this.isClinic ? 'Clinic'
      : this.isCamp ? 'Camp'
        : this.isTryout ? 'Tryout'
          : this.isLeague ? 'League'
            : this.isSeries ? 'Tournament Series'
              : this.isYouthProgram ? 'Youth Program'
                : this.isEvaluationDay ? 'Evaluation Day'
                  : this.isTrainingSession ? 'Training Session'
                    : this.isPrivateLesson ? 'Private Lesson'
                      : 'Tournament'
  }

  get eType () {
    return this.isSeries ? 'Series'
      : this.isYouthProgram ? 'Program'
        : this.eventType
  }

  get isCompetitive () {
    return this.isLeague || this.isTournament
  }

  get reoccuring () {
    return this.isLeague || this.isTrainingSession
  }

  get calenderEvents () {
    return this.reoccuring ? this.dates.map(d => {
      return {
        id: this.id,
        name: this.eventType,
        start: d,
        end: d
      }
    }) : [{
      id: this.id,
      name: this.eventType,
      start: this.startDate.format('YYYY-MM-DD hh:mm'),
      end: this.endDate.format('YYYY-MM-DD hh:mm')
    }]
  }

  isWithin (maxDistance, latLong) {
    if (!this.coordinates) return false
    const splt = latLong.split(' ')
    const lat = splt[0]
    const long = splt[1]
    const found = this.coordinates.find(f => {
      const splt2 = f.split(' ')
      const lat2 = splt2[0]
      const long2 = splt2[1]
      const d = distance(lat, long, lat2, long2)
      return d <= maxDistance
    })
    return !!found
  }

  distance (latLong) {
    if (!this.coordinates) return false
    if (!latLong) return false
    const splt = latLong.split(' ')
    const lat = splt[0]
    const long = splt[1]
    const found = this.coordinates.map(f => {
      const splt2 = f.split(' ')
      const lat2 = splt2[0]
      const long2 = splt2[1]
      const d = distance(lat, long, lat2, long2)
      return d
    })
    return found
  }
}
