import PoolTeam from './PoolTeam'
import firstBy from 'thenby'
import PoolMatches from './PoolMatches'
import Match from './Match'
import moment from 'moment'
import flatten from '../helpers/ArrayFlatten'
import sum from 'lodash.sum'
import uniq from 'lodash.uniq'
import * as filters from '../Filters'
import TieBreakers from './Tiebreaks'
import DtoUpdate from './DtoUpdate'
import PoolPlayOptionDescriptor from '@/classes/PoolPlayOptionDescriptor'
import Dual from './Dual'

export default class Pool {
  name = 0
  id = 0
  courts = ''
  assignTimes = true
  minutesPerMatch = 30
  startTime = null
  teams = []
  settings = null
  matches = []
  addMatches = []
  tiebreaks = null
  tiebreakNotes = []
  locked = false
  _dto = null
  holder = false
  fivb = false
  fivbPerformaance = false

  constructor (sdk, dto, settings) {
    this.sdk = sdk
    this.settings = settings

    if (dto) {
      this.update(dto)
    }
  }

  // methods
  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['teams', 'matches']
    DtoUpdate(dto, this, exclude)

    if (dto && dto.teams) {
      this.teams = dto.teams.sort(firstBy('slot')).map(t => new PoolTeam(this.sdk, t))
    }
    if (dto && dto.matches) {
      dto.matches.sort(firstBy('number'))
      dto.matches.forEach(match => {
        const m = this.matches.find(f => (match.id && match.id === f.id) || f.number === match.number)
        if (m) {
          m.update(match)
        } else {
          this.matches.push(new Match(this.sdk, match, 'pool'))
        }
      })
    }
    if (!this.holder) {
      // this.updateMatches(dto)
      this.summarize()
    }
    this._dto = null
    this.matches.sort(firstBy('unix').thenBy('number'))
  }

  setFivbPerformaance (v) {
    this.fivbPerformaance = v
  }

  edit () {
    this._dto = JSON.stringify(this.dto)
  }

  restore () {
    if (this.isDirty()) this.update(this._dto)
    this._dto = null
  }

  isDirty () {
    return this._dto !== JSON.stringify(this.dto) || this.id === 0
  }

  updateMatches (dto) {
    const template = PoolMatches.find(f => f.teams === this.teams.length)
    if (!template) return
    this.matches = template.matches.map(m => {
      const home = this.teams.find(f => f.slot === m.home)
      const away = this.teams.find(f => f.slot === m.away)
      const match = (dto.matches || []).find(dm => dm.homeTeam.id === home.id && dm.awayTeam.id === away.id)
      if (match) {
        match.number = m.number
        return new Match(this.sdk, match, 'pool')
      }

      const ref = this.teams.find(f => f.slot === m.ref)
      const games = this.setting && this.setting.gameSettings ? JSON.parse(JSON.stringify(this.setting.gameSettings)) : []
      return new Match(this.sdk, {
        poolId: this.id,
        number: m.number,
        homeTeam: home,
        awayTeam: away,
        refTeam: ref,
        isMatch: this.setting ? this.setting && this.setting.isMatch : false,
        games: games,
        court: this.courts
      }, 'pool')
    })
    if (this.settings.assignTimes) {
      for (let i = 0; i < this.matches.length; i++) {
        const match = this.matches[i]
        if (i === 0) {
          match.startTime = moment.parseZone(this._startTime)
        } else {
          let t = moment(this.matches[i - 1].startTime)
          t = t.add(this.setting.minutesPerMatch, 'm')
          match.startTime = t
        }
      }
    }
  }

  patch (dto) {
    dto.id = this.id
    return this.sdk.patch.pool(dto)
  }

  addTeam (id) {
    this.teams.push(new PoolTeam(this.sdk, { teamId: id, slot: this.teams.length + 1 }))
  }

  addMatch (dto) {
    this.matches.push(new Match(this.sdk, dto, 'pool'))
  }

  findMatchBetween (id1, id2) {
    return this.matches.find(match => match.isBetween([id1, id2]))
  }

  rankByRecord () {
    if (this.isDuals) {
      this.rankByDualRecord()
      return
    }
    const teams = this.teams.filter(t => !t.forfeit)
    const matchWinRatios = uniq(teams.map(r => r.matchWinRatio)).sort().reverse()
    let i = 1
    matchWinRatios.forEach(r => {
      const _teams = teams.filter(f => f.matchWinRatio === r)
      _teams.forEach(team => {
        team.rank = i
      })
      i = i + _teams.length
    })
    const fTeams = this.teams.filter(t => t.forfeit)
    let x = 99
    fTeams.forEach(t => {
      t.rank = x++
    })
  }

  rankByDualRecord () {
    console.log('rankByDualRecord')
    const teams = this.teams.filter(t => !t.forfeit)
    const dualWinRatios = uniq(teams.map(r => r.dualWinRatio)).sort().reverse()
    let i = 1
    dualWinRatios.forEach(r => {
      const _teams = teams.filter(f => f.dualWinRatio === r)
      _teams.forEach(team => {
        team.rank = i
      })
      i = i + _teams.length
    })
    const fTeams = this.teams.filter(t => t.forfeit)
    let x = 99
    fTeams.forEach(t => {
      t.rank = x++
    })
  }

  hasTies (teams) {
    const currentRanks = uniq(teams.map(t => t.rank))
    return currentRanks.length !== teams.length
  }

  breakTies () {
    if (this.fivb && this.teams.length === 4 && !this.fivbPerformaance) {
      if (!this.complete) return
      const secondThird = this.teams.filter(t => t.rank === 2)
      const l = this.matches.find(f => f.number === 3)
      const third = l && (secondThird.find(f => l.homeTeam && f.id === l.homeTeam.id) || secondThird.find(f => l.awayTeam && f.id === l.awayTeam.id))
      if (third) third.rank = 3
      return
    }
    const teams = this.teams.filter(t => !t.forfeit)
    this.tiebreakNotes = []
    let changed = true
    // let i = 1
    while (changed && this.hasTies(teams)) {
      // this.tiebreakNotes.push(`Tie break round ${i++}`)
      const ranks = uniq(teams.map(t => t.rank)).sort()

      let _teams = []

      const r = ranks.find(rank => {
        const t = teams.filter(f => f.rank === rank)
        if (t.length > 1) {
          _teams = t
          return true
        }
      })
      this.tiebreakNotes.push({ text: `${_teams.length}-way tie for ${filters.ordinal(r)}`, header: true })

      this.tiebreakers.filter(f => f.name !== 'DUAL_RECORD').some(tb => {
        // do tie break
        this[tb.name](_teams)
        // check for change
        var newRanks = uniq(teams.map(t => t.rank)).sort()
        changed = JSON.stringify(ranks) !== JSON.stringify(newRanks)
        return changed
      })
    }
    const tbMatches = this.matches.filter(f => f.isTiebreak && f.complete)
    tbMatches.forEach(m => {
      const w = this.teams.find(f => f.id === m.winningId)
      if (!w) return
      const wr = w.rank
      const l = this.teams.find(f => f.id === m.losingId)
      if (!l) return
      const lr = l.rank
      if (lr < wr) {
        w.rank = lr
        l.rank = wr
        this.tiebreakNotes.push({ text: `Playoff: ${filters.formatArray([lr, wr])} was decided by ${m.title}.` })
      }
    })
  }

  ['HEAD_2_HEAD'] (teams) {
    if (teams.length !== 2) {
      this.tiebreakNotes.push({ text: 'H2H: Can\'t be broken head to head - More than 2 teams' })
      return
    }
    let match = this.matches.find(match => match.isBetween([teams[0].id, teams[1].id]) && !match.isTiebreak)
    if (!match) {
      this.tiebreakNotes.push({ text: 'H2H: Can\'t be broken head to head - No H2H' })
      return
    }
    if (this.isDuals) {
      match = this.duals.find(f => f.n === match.dualN)
    }
    if (match.complete) {
      if (match.tbWinner === 'split') {
        this.tiebreakNotes.push({ text: 'H2H: Can\'t be broken head to head - Split sets' })
        return
      }
      if (teams[0].id === match.tbWinnerId) {
        teams[1].rank += 1
      } else {
        teams[0].rank += 1
      }
      const ranks = teams.map(t => t.rank).sort().map(x => filters.ordinal(x))
      this.tiebreakNotes.push({ text: `H2H: ${filters.formatArray(ranks)} decided by ${this.isDuals ? 'dual' : 'match'} ${this.isDuals ? match.n : match.number}` })
      return
    }
    this.tiebreakNotes.push({ text: 'H2H: Head to head match not complete' })
  }

  ['POINTS_BETWEEN_3'] (teams) {
    if (teams.length < 3) {
      this.tiebreakNotes.push({ text: 'PATT 3+: Can\'t be broken with PATT - Less than 3 teams' })
      return
    }

    return this.POINTS_BETWEEN(teams)
  }

  ['POINTS_BETWEEN'] (teams) {
    let i = teams[0].rank
    const matches = this.matches.filter(m => m.isBetween(teams.map(m => m.id)) && !m.isTiebreak)
    teams.forEach(team => {
      const hMatches = matches.filter(m => m.homeTeam.id === team.id)
      const aMatches = matches.filter(m => m.awayTeam.id === team.id)
      team.tb_pointsFor = sum(flatten(hMatches.map(m => m.homePointsFor))) + sum(flatten(aMatches.map(m => m.awayPointsFor)))
      team.tb_pointsAgainst = sum(flatten(hMatches.map(m => m.homePointsAgainst))) + sum(flatten(aMatches.map(m => m.awayPointsAgainst)))
      team.tb_pointDiff = team.tb_pointsFor - team.tb_pointsAgainst
      team.tb_pointDiffRatio = team.tb_pointsFor / team.tb_pointsAgainst
    })
    const ratios = uniq(teams.map(r => r.tb_pointDiff)).sort((a, b) => { return b - a })
    if (ratios.length === 1) {
      this.tiebreakNotes.push({ text: `PATT: All teams have point differential of ${ratios[0]}` })
      return
    }
    const ranks = []
    ratios.forEach(r => {
      const _teams = teams.filter(f => f.tb_pointDiff === r)
      _teams.forEach(team => {
        team.rank = i
        this.tiebreakNotes.push({ text: `PATT: ${filters.ordinal(i)} team ${team.slot} (${team.tb_pointDiff} point differential).` })
      })
      ranks.push(filters.ordinal(i))
      i = i + _teams.length
    })
    this.tiebreakNotes.push({ text: `PATT: ${filters.formatArray(ranks)} was decided.` })
  }

  ['OVERALL_POINTS'] (teams) {
    let i = teams[0].rank
    const diffs = uniq(teams.map(r => r.pointDiff)).sort(function (a, b) { return b - a })
    if (diffs.length === 1) {
      this.tiebreakNotes.push({ text: `OP: All teams have a point differential of ${diffs[0]}` })
      return
    }
    const ranks = []
    diffs.forEach(d => {
      const _teams = teams.filter(f => f.pointDiff === d)
      _teams.forEach(team => {
        team.rank = i
        this.tiebreakNotes.push({ text: `OP: ${filters.ordinal(i)} team ${team.slot} (${team.pointDiff} point differential).` })
      })
      ranks.push(filters.ordinal(i))
      i = i + _teams.length
    })
    this.tiebreakNotes.push({ text: `OP: ${filters.formatArray(ranks)} decided.` })
  }

  ['ORIGINAL_SEED'] (teams) {
    teams.sort(firstBy('seed'))
    let i = 0
    const ranks = []
    teams.forEach(team => {
      team.rank += i++
      ranks.push(team.rank)
      this.tiebreakNotes.push({ text: `OS: ${filters.ordinal(team.rank)} team ${team.slot} (Seeded ${team.seed})` })
    })
    this.tiebreakNotes.push({ text: `OS: ${filters.formatArray(ranks)} decided.` })
  }

  ['SET_WIN_RATIO'] (teams) {
    const setWinRatios = uniq(teams.map(r => r.setWinRatio)).sort().reverse()
    if (setWinRatios.length === 1) {
      this.tiebreakNotes.push({ text: `SWR: All teams have set win ratio of ${filters.fixed3(setWinRatios[0])}` })
      return
    }
    let i = teams[0].rank
    const ranks = []
    setWinRatios.forEach(r => {
      const _teams = teams.filter(f => f.setWinRatio === r)
      _teams.forEach(team => {
        team.rank = i
        this.tiebreakNotes.push({ text: `SWR: ${filters.ordinal(i)} team ${team.slot} (${filters.fixed2(team.setWinRatio)} ratio).` })
      })
      ranks.push(filters.ordinal(i))
      i = i + _teams.length
    })
    this.tiebreakNotes.push({ text: `SWR: ${filters.formatArray(ranks)} was decided.` })
  }

  ['MATCH_WIN_RATIO'] (teams) {
    const matchWinRatios = uniq(teams.map(r => r.matchWinRatio)).sort().reverse()
    if (matchWinRatios.length === 1) {
      this.tiebreakNotes.push({ text: `MWR: All teams have match win ratio of ${filters.fixed3(matchWinRatios[0])}` })
      return
    }
    let i = teams[0].rank
    const ranks = []
    matchWinRatios.forEach(r => {
      const _teams = teams.filter(f => f.matchWinRatio === r)
      _teams.forEach(team => {
        team.rank = i
        this.tiebreakNotes.push({ text: `MWR: ${filters.ordinal(i)} team ${team.slot} (${filters.fixed2(team.matchWinRatio)} ratio).` })
      })
      ranks.push(filters.ordinal(i))
      i = i + _teams.length
    })
    this.tiebreakNotes.push({ text: `MWR: ${filters.formatArray(ranks)} was decided.` })
  }

  ['POINTS_FOR'] (teams) {
    let i = teams[0].rank
    const diffs = uniq(teams.map(r => r.pointsFor)).sort(function (a, b) { return b - a })
    if (diffs.length === 1) {
      this.tiebreakNotes.push({ text: `PF: All teams have total points of ${diffs[0]}` })
      return
    }
    const ranks = []
    diffs.forEach(d => {
      const _teams = teams.filter(f => f.pointsFor === d)
      _teams.forEach(x => {
        x.rank = i
        x.dRank = i
        this.tiebreakNotes.push({ text: `PF: ${filters.ordinal(i)} team ${x.slot} (${x.pointsFor} total point).` })
      })
      ranks.push(filters.ordinal(i))
      i = i + _teams.length
    })
    this.tiebreakNotes.push({ text: `PD: ${filters.formatArray(ranks)} decided.` })
  }

  summarize () {
    this.teams.forEach(team => {
      if (team.summary && team.summary.id > 0) {
        team.dualWins = team.summary.dualWins
        team.dualLosses = team.summary.dualLosses
        team.matchWins = team.summary.matchWins
        team.matchLosses = team.summary.matchLosses
        team.setWins = team.summary.setWins
        team.setLosses = team.summary.setLosses
        team.pointsFor = team.summary.pointsFor
        team.pointsAgainst = team.summary.pointsAgainst
      }
      team.locked = this.locked

      const matchesToConsider = this.isDuals ? flatten(this.duals.map(m => m.pointMatches)) : this.matches

      // const hMatches = this.matches.filter(m => m.homeTeam && m.homeTeam.id === team.id)
      const hMatches = matchesToConsider.filter(m => (m.homeTeam && m.homeTeam.id === team.id && !m.isTiebreak) || (m.homeTeamIds && m.homeTeamIds.includes(team.teamId) && !m.isTiebreak))
      // const aMatches = this.matches.filter(m => m.awayTeam && m.awayTeam.id === team.id)
      const aMatches = matchesToConsider.filter(m => (m.awayTeam && m.awayTeam.id === team.id && !m.isTiebreak) || (m.awayTeamIds && m.awayTeamIds.includes(team.teamId) && !m.isTiebreak))
      // const w = hMatches.filter(m => m.winner === 'home').length
      // console.log(this.setting)
      team.setWins = sum(flatten(hMatches.map(m => m.homeSetWins))) + sum(flatten(aMatches.map(m => m.awaySetWins)))
      team.setLosses = sum(flatten(hMatches.map(m => m.homeSetLosses))) + sum(flatten(aMatches.map(m => m.awaySetLosses)))
      team.matchWins = this.setting && (this.setting.isMatch || this.setting.isTimed) ? hMatches.filter(m => m.winner === 'home').length + aMatches.filter(m => m.winner === 'away').length : team.setWins
      team.matchLosses = this.setting && (this.setting.isMatch || this.setting.isTimed) ? hMatches.filter(m => m.winner === 'away').length + aMatches.filter(m => m.winner === 'home').length : team.setLosses
      // team.matchWins = this.setting && this.setting.isMatch ? this.matches.filter(m => m.winningId === team.id && !m.isTiebreak).length : team.setWins
      // team.matchLosses = this.setting && this.setting.isMatch ? this.matches.filter(m => m.losingId === team.id && !m.isTiebreak).length : team.setLosses
      team.dualWins = this.isDuals ? this.duals.filter(m => m.winningId === team.id).length : 0
      team.dualLosses = this.isDuals ? this.duals.filter(m => m.losingId === team.id).length : 0
      team.pointsFor = sum(flatten(hMatches.map(m => m.homePointsFor))) + sum(flatten(aMatches.map(m => m.awayPointsFor)))
      team.pointsAgainst = sum(flatten(hMatches.map(m => m.homePointsAgainst))) + sum(flatten(aMatches.map(m => m.awayPointsAgainst)))
      team.pointsPlayed = sum(flatten(hMatches.map(m => m.pointsPlayed))) + sum(flatten(aMatches.map(m => m.pointsPlayed)))
    })
    if (this.completeMatches.length === 0) return
    if (this.isDuals && this.completeDuals.length === 0) return

    // if (this.summary && this.summary.id > 0) return
    if (this.isAndrewPool) {
      this.rankAndrewCrossPool()
    } else if (this.isCrossPool) {
      this.rankCrossPool()
    } else {
      this.rankByRecord()
      try {
        this.breakTies()
      } catch (e) {
        console.log(e)
      }
    }
  }

  rankAndrewCrossPool () {
    var base = this.isDuals ? this.duals : this.matches
    const seeds = [[], [1, 3], [2, 4], [5, 6]]

    base.forEach(m => {
      const s = seeds[m.number]
      const h = this.teams.find(f => f.id === m.homeTeam.id)
      const a = this.teams.find(f => f.id === m.awayTeam.id)
      if (m.complete) {
        h.rank = m.winner === 'home' ? s[0] : s[1]
        a.rank = m.winner === 'home' ? s[1] : s[0]
      } else {
        h.rank = a.rank = s[0]
      }
    })
  }

  rankCrossPool () {
    var base = this.isDuals ? this.duals : this.matches

    base.forEach(m => {
      const top = (m.number * 2) - 1
      const h = this.teams.find(f => f.id === m.homeTeam.id)
      const a = this.teams.find(f => f.id === m.awayTeam.id)
      if (m.complete) {
        h.rank = top + (m.winner === 'home' ? 0 : 1)
        a.rank = top + (m.winner === 'home' ? 1 : 0)
      } else {
        h.rank = a.rank = top
      }
    })
  }

  updateMatchMeta (tournament, division, day) {
    this.matches.forEach(m => m.updateMatchMetaP(tournament, division, day, this))
  }

  getMatchMeta (tournament, division, day) {
    return this.matches.map(m => m.getMatchMetaP(tournament, division, day, this))
  }

  // getters/setters
  // getters
  get dirty () {
    return this._dto !== JSON.stringify(this.dto) || this.id < 0
  }

  get isCrossPool () {
    var base = this.isDuals ? this.duals : this.matches
    var matchGaps = base.map(m => m.slotGap)
    matchGaps = matchGaps.filter(f => f !== 1)
    return this.teams.length === base.length * 2 && matchGaps.length === 0
  }

  get isAndrewPool () {
    var base = this.isDuals ? this.duals : this.matches
    if (base.length !== 3) return false
    var matchGaps = JSON.stringify(base.map(m => m.slotGap))

    return matchGaps === '[2,2,1]'
  }

  get dirtyTeams () {
    return this.teams.filter(f => f.dirty)
  }

  get dto () {
    return {
      id: this.id,
      name: this.name,
      courts: this.courts,
      teams: this.teams.map(t => t.dto),
      assignTimes: this.assignTimes,
      minutesPerMatch: this.minutesPerMatch,
      startTime: this.startTime
    }
  }

  get setting () {
    const actievTeams = this.teams.filter(f => !f.forfeit).length
    return this.settings.find(f => f.poolsOf === actievTeams) || this.settings.find(f => f.poolsOf === this.teams.length) || this.settings.find(f => f.poolsOf === 0)
  }

  get matchDescription () {
    if (!this.setting) return null
    const d = new PoolPlayOptionDescriptor(this.setting)
    return d.description
  }

  get rankedTeams () {
    const completeMatches = this.matches.filter(m => m.complete)

    if (completeMatches.length > 0) {
      return this.teams.sort(firstBy('rank').thenBy('slot'))
    }
    return this.teams.sort(firstBy('slot'))
  }

  get unrankedTeams () {
    return this.teams.sort(firstBy('slot'))
  }

  get tiebreakers () {
    const map = function (breaks) {
      return breaks.split(',').map(m => {
        return TieBreakers.find(f => f.name === m.trim())
      })
    }

    const breaks = this.tiebreaks || `HEAD_2_HEAD,${this.isDuals ? 'MATCH_WIN_RATIO,' : ''}${this.setting && this.setting.isMatch ? 'SET_WIN_RATIO,' : ''}POINTS_BETWEEN,OVERALL_POINTS,ORIGINAL_SEED`
    return map(breaks)
  }

  get completeMatches () {
    return this.matches.filter(m => m.complete && !m.isBye)
  }

  get ongoingMatches () {
    if (this.locked) return []
    return this.matches.filter(f => f.status === 'Started' && !!f.currentGame)
  }

  get completeDuals () {
    return this.duals.filter(m => !!m.winner)
  }

  get complete () {
    const a = this.matches.length > 0 && this.matches.length === this.completeMatches.length
    const b = this.duals.length > 0 && this.duals.length === this.completeDuals.length
    return this.locked || (this.isDuals ? b : a)
  }

  get status () {
    return this.complete ? 'Complete' : this.matches.filter(m => m.status).length > 0 ? 'Started' : null
  }

  get teamIds () {
    return this.teams.map(t => t.teamId)
  }

  get _startTime () {
    const dt = this.startTime || this.setting.startTime
    // if (!dt.endsWith('Z')) dt = dt + 'Z'
    return dt
  }

  get allMatches () {
    return this.matches
  }

  get isDuals () {
    return this.matches.filter(f => f.number > 1000).length > 0
  }

  get duals () {
    var numbers = uniq(this.matches.filter(f => f.number > 1000).map(m => m.dualN)).sort(firstBy(Number))
    return numbers.map(n => {
      return new Dual(n, this.matches.filter(f => f.dualN === n))
    })
  }

  getMatchSummariesOG (teamId) {
    return this.matches.map(m => {
      const item = m.timeLineItems.find(f => f.teamId === teamId)

      return item || {
        teamId: teamId,
        what: 'Sit',
        when: m.startTime,
        where: m.court,
        status: m.status,
        matchN: m.type === 'bracket' ? this.displayNumber : this.number
      }
    })
  }

  getMatchSummaries (teamId) {
    if (this.isDuals) {
      return this.getDualSummaries(teamId)
    }
    const s = []
    this.matches.forEach(m => {
      const d = m.timeLineItems.filter(f => f.teamId === teamId)
      if (d.length) {
        s.push(...d)
      } else {
        const tbd = m.timeLineItems.filter(f => f.what === 'Play').length < 2 && (m.awayMap || m.homeMap)
        s.push({
          teamId: teamId,
          what: tbd ? 'TBD' : m.complete ? 'Sat' : 'Sit',
          when: m.startTime,
          unix: m.startTime ? m.startTime.unix() : null,
          where: m.court,
          status: m.status,
          matchN: m.type === 'bracket' ? m.displayNumber : m.number,
          complete: m.complete
        })
      }
    })
    return s
  }

  getDualSummaries (teamId) {
    const s = []
    this.duals.forEach(m => {
      const d = m.timeLineItems.filter(f => f.teamId === teamId)
      if (d.length) {
        d.forEach(dd => {
          dd.matches = dd.matches.filter(f => f.teamId === teamId)
        })
        s.push(...d)
      } else {
        s.push({
          teamId: teamId,
          what: m.complete ? 'Sat' : 'Sit',
          when: m.startTime,
          unix: m.startTime ? m.startTime.unix() : null,
          status: m.status,
          matchN: m.displayN,
          who: []
        })
      }
    })
    return s
  }

  getSlot (teamId) {
    const t = this.teams.find(f => f.teamId === teamId)
    return t ? t.slot : null
  }

  get lockReady () {
    return this.complete && !this.locked
  }

  get tbDone () {
    return this.matches.filter(f => f.isTiebreak && f.complete) > 0
  }

  get startTimeCalc () {
    return this.matchTimes && this.matchTimes[0]
  }

  get matchTimes () {
    return this.matches && this.matches.map(m => m.startTime)
  }

  get hasMatches () {
    return this.matches && this.matches.filter(m => m.isMatch).length > 0
  }
}
