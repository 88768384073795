import moment from 'moment'
import { firstBy } from 'thenby'

const usDollars = (amount) => !isNaN(amount) ? '$' + parseFloat(amount).toFixed(2) : amount
const usPhone = (phone) => phone ? phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : ''
const ordinal = (i) => {
  if (!i) return null
  if (typeof i !== 'number') {
    const parsed = Number.parseInt(i)
    if (Number.isNaN(parsed)) {
      return i
    }
    i = parsed
  }

  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return `${i}st`
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`
  }
  return `${i}th`
}
const ordinalSuffix = (i) => {
  if (!i) return null
  if (typeof i !== 'number') return ''

  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return 'st'
  }
  if (j === 2 && k !== 12) {
    return 'nd'
  }
  if (j === 3 && k !== 13) {
    return 'rd'
  }
  return 'th'
}
const localDt = (date) => {
  // if (typeof date === 'string') date = date.replace('Z', '')
  // return moment(date).format('L LT')
  return unixToLocalDt(date)
}
const unixToLocalDt = (date) => {
  if (!date) return null
  return moment(date).format('L LT')
}
const localDate = (date) => {
  if (typeof date === 'string') date = date.replace('Z', '')
  return moment(date).format('L')
}
const readableDate = date => {
  if (typeof date === 'string') date = date.replace('Z', '')
  return moment(date).format('dddd, MMMM Do, YYYY')
}
const readableDateRange = (d1, d2) => {
  if (!moment.isMoment(d1)) d1 = moment(d1)
  if (!moment.isMoment(d2)) d2 = moment(d2)

  if (d1.isSame(d2)) return d1.format('dddd, MMMM Do, YYYY')

  if (d1.format('MMMM') === d2.format('MMMM')) return `${d1.format('MMMM Do')} - ${d2.format('Do YYYY')}`

  return `${d1.format('MMMM Do')} - ${d2.format('MMMM Do, YYYY')}`
}
const time = date => {
  return date ? moment.parseZone(date).format('h:mm A') : null
}
const shortDate = date => {
  const d = date.includes('T') ? date.substring(0, 10) : date
  return moment.parseZone(d).format('MMM Do, YYYY')
}
const formatArray = (arr) => {
  if (!arr) return null
  if (typeof arr === 'string') arr = arr.split(',')
  arr = arr.filter(f => !!f)
  return arr.length === 0 ? ''
    : arr.length === 1 ? arr[0]
      : arr.length === 2 ? arr.join(' & ')
        : arr.slice(0, -1).join(', ') + ', & ' + arr.slice(-1)
}
const formatArraySort = (arr) => {
  if (!arr) return null
  if (typeof arr === 'string') arr = arr.split(',')
  arr = arr.filter(f => !!f).sort(firstBy(Number))
  return arr.length === 0 ? ''
    : arr.length === 1 ? arr[0]
      : arr.length === 2 ? arr.join(' & ')
        : arr.slice(0, -1).join(', ') + ', & ' + arr.slice(-1)
}
const formatArrayOr = (arr) => {
  if (!arr) return null
  if (typeof arr === 'string') arr = arr.split(',')
  arr = arr.filter(f => !!f)
  return arr.length === 0 ? ''
    : arr.length === 1 ? arr[0]
      : arr.length === 2 ? arr.join(' or ')
        : arr.slice(0, -1).join(', ') + ', or ' + arr.slice(-1)
}
const pipeArray = (arr) => {
  if (!arr) return null
  if (typeof arr === 'string') arr = arr.split(',')
  arr = arr.filter(f => !!f)
  return arr.length === 0 ? ''
    : arr.length === 1 ? arr[0]
      : arr.join(' | ')
}
const slashArray = (arr) => {
  if (!arr) return null
  if (typeof arr === 'string') arr = arr.split(',')
  arr = arr.filter(f => !!f)
  return arr.length === 0 ? ''
    : arr.length === 1 ? arr[0]
      : arr.join(' / ')
}
const formatArraySorted = (arr) => {
  if (!arr) return null
  if (typeof arr === 'string') arr = arr.split(',')
  arr.sort()
  return arr.length === 0 ? ''
    : arr.length === 1 ? arr[0]
      : arr.length === 2 ? arr.join(' & ')
        : arr.slice(0, -1).join(', ') + ', & ' + arr.slice(-1)
}
const fixed1 = (n) => {
  return n && !isNaN(n) ? parseFloat(n).toFixed(1) : n
}
const fixed1OrInt = (n) => {
  return n && !isNaN(n) && (n % 1 !== 0) ? parseFloat(n).toFixed(1) : n
}
const fixed2OrInt = (n) => {
  const a = parseFloat(n).toFixed(2)
  return a.endsWith('.00') ? ~~n : a
}
const pointArray = (arr) => {
  var arr2 = arr.map(m => fixed1OrInt(m))
  return pipeArray(arr2)
}
const fixed2 = (n) => {
  return n ? parseFloat(n).toFixed(2) : 0
}
const fixed3 = (n) => {
  return n ? parseFloat(n).toFixed(3) : 0
}
const fixed2Up = (n) => {
  return n > 0 ? fixed2(Math.ceil(n * 100) / 100) : '0.00'
}

const twoDigits = (n) => {
  return `${n}`.padStart(2, 0)
}
const ageDivision = (age) => {
  if (!age) return ''
  if (age <= 10) return '10U'
  // if (age <= 12) return '12U'
  // if (age <= 14) return '14U'
  // if (age <= 15) return '15U'
  // if (age <= 16) return '16U'
  if (age <= 18) return `${age}U`
  if (age <= 25) return 'U26'
  return 'Adult'
}
const ageDivisionYouth = (age) => {
  if (!age) return ''
  if (age <= 10) return '10U'
  // if (age <= 12) return '12U'
  // if (age <= 14) return '14U'
  // if (age <= 15) return '15U'
  // if (age <= 16) return '16U'
  if (age <= 18) return `${age}U`
  if (age <= 19) return '18U'
  if (age <= 25) return 'U26'
  return ''
}
const intelliFormat = (x) => {
  if (typeof x === 'number') return fixed1OrInt(x)
  if (moment.isMoment(x)) return x.format('l LT')
  return x
}

const bestFinish = (events) => {
  events = events.filter(f => f.finish)
  if (!events || !events.length) return 'NA'
  const finishes = events.map(m => m.finish).sort(firstBy(Number))
  const finish = finishes[0]
  const times = finishes.filter(f => f === finish).length

  let r = finish < 9999 ? ordinal(finish) : 'DNP'
  if (finish < 9999 && events.length > 1) {
    r = r + ` (${times} time${times > 1 ? 's' : ''})`
  }
  return r
}

const version = (n) => {
  return n.toString().indexOf('.') === -1 ? fixed1(n) : n
}

const singles = (n) => {
  return (n || 0).toString().split('').pop()
}

const tens = (n) => {
  const x = (n || 0).toString().split('')
  x.pop()
  return x.length ? x.join('') : 0
}

const capFirst = (s) => {
  if (typeof s !== 'string') return s

  return s.charAt(0).toUpperCase() + s.slice(1)
}

const trim100 = (s) => {
  return trim(s, 100)
}

const trim50 = (s) => {
  return trim(s, 50)
}

const trim = (s, l) => {
  if (!s || s.length <= l) return s
  return `${s.substring(0, l - 1)}...`
}

const ratingDisplay = (r) => {
  if (!r) return 'Unsanctioned'
  const age = r.replace(/\D/g, '')
  if (!age) return r
  return `${age}U ${r.replace(age, '')}`
}

const ratingDisplayAlpha = (r) => {
  if (!r) return 'Unsanctioned'
  const age = r.replace(/\D/g, '')
  if (!age) return r
  return `${r.replace(age, '')}`
}

const ratingAge = (r) => {
  return r && `${r.replace(/\D/g, '')}U`
}

const time24To12 = (time) => {
  if (moment.isMoment(time)) {
    return time.format('h:mm A')
  }
  return time ? (time.toLowerCase().includes('am') || time.toLowerCase().includes('pm'))
    ? moment(time, ['h:mmA']).format('h:mm A') : moment(time, ['HH:mm']).format('h:mm A') : ''
}

export {
  usDollars,
  usPhone,
  ordinal,
  ordinalSuffix,
  localDt,
  readableDate,
  readableDateRange,
  time,
  shortDate,
  formatArray,
  formatArraySorted,
  fixed1,
  fixed2,
  fixed2Up,
  fixed3,
  twoDigits,
  ageDivision,
  ageDivisionYouth,
  intelliFormat,
  pipeArray,
  slashArray,
  fixed1OrInt,
  fixed2OrInt,
  pointArray,
  bestFinish,
  version,
  singles,
  tens,
  capFirst,
  localDate,
  formatArrayOr,
  unixToLocalDt,
  formatArraySort,
  trim100,
  trim50,
  ratingDisplay,
  ratingAge,
  ratingDisplayAlpha,
  time24To12
}
