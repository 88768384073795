import moment from 'moment'

export default class User {
  constructor (dto) {
    this.images = []
    this.pages = []
    this.vbl = false
    this.pwResetTill = null
    this.member = null
    this.bidPrograms = null
    this.bookmarks = []
    this.roles = []
    this.lastPwSet = null
    this.collegeTeams = []
    this.props = []
    this.update(dto)
  }

  // methods
  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
    this.pages.forEach(p => {
      p.username = p.username.toLowerCase()
    })
  }

  isPageAdmin (pageUsername) {
    if (this.vbl) return true
    if (!this.pages || this.pages.length === 0) return false

    let page = null
    if (typeof pageUsername === 'string') {
      page = this.pages.find(p => p.username === pageUsername)
    } else {
      page = this.pages.find(p => p.id === pageUsername)
    }
    return page != null
  }

  isTournamentAdmin (tournament) {
    if (this.vbl) return true
    if (!tournament || !this.pages || this.pages.length === 0) return false

    if (tournament.organization
      ? this.pages.findIndex((page) => page.id === tournament.organization.id) !== -1
      : this.pages.findIndex((page) => tournament.organizers.includes(page.id)) !== -1
    ) return true

    if (tournament.sanctionedBy && this.pages.findIndex(p => p.username === tournament.sanctionedBy.toLowerCase()) !== -1) return true

    if (tournament.allSanctionBodies.length > 0) {
      let isSB = false
      tournament.allSanctionBodies.forEach(f => {
        const x = this.pages.findIndex(p => p.username === f.toLowerCase())
        if (x !== -1) {
          isSB = true
        }
      })

      if (isSB) return true
    }

    return false
  }

  get isAvpAdmin () {
    if (this.vbl) return true
    return this.pages.findIndex(p => p.username === 'avpa') !== -1
  }

  isSbAdmin (tournament) {
    if (tournament.sanctionedBy && this.pages.findIndex(p => p.username === tournament.sanctionedBy.toLowerCase()) !== -1) return true

    if (tournament.allSanctionBodies.length > 0) {
      let isSB = false
      tournament.allSanctionBodies.forEach(f => {
        const x = this.pages.findIndex(p => p.username === f.toLowerCase())
        if (x !== -1) {
          isSB = true
        }
      })

      if (isSB) return true
    }

    return false
  }

  isOrganizationAdmin (organizationId) {
    if (this.vbl) return true
    if (!organizationId || !this.pages || this.pages.length === 0) return false

    return this.pages.findIndex((page) => page.id === organizationId) !== -1
  }

  isPlayerAdmin (id) {
    if (this.vbl) return true
    return !!this.profiles.find(f => f.profile.id === id)
  }

  isTeamAdmin (teamId) {
    var t = this.collegeTeams.findIndex(f => f.id === +teamId)
    return t > -1
  }

  // getters

  get isPlayer () {
    return this.profiles.length
  }

  get isCollegeCoach () {
    return this.roles.includes('CollegeCoach') || this.vbl
  }

  get tdOrganizations () {
    return this.pages.filter(p => p.props.includes('TD'))
  }

  get isTD () {
    return this.tdOrganizations.length > 0
  }

  get canTag () {
    return this.isCollegeCoach || this.isTd || this.vbl
  }

  get primaryEmail () {
    return this.emails.find(email => email.isPrimary)
  }

  get primaryPhone () {
    return this.phones.find(phone => phone.isPrimary)
  }

  get fullname () {
    return this.firstName + ' ' + this.lastName
  }

  get verifiedEmails () {
    return this.emails.filter(f => f.isVerified)
  }

  get verifiedPhones () {
    return this.phones.filter(f => f.isVerified)
  }

  get hasVerifiedContact () {
    return this.verifiedEmails.length > 0 || this.verifiedPhones.length > 0
  }

  get canSetPassword () {
    if (!(this.pwResetTill && moment(this.pwResetTill).isAfter())) return false
    if (!this.lastPwSet) return true
    return moment(this.pwResetTill).isAfter() && moment(this.lastPwSet).add(15, 'm').isBefore()
  }

  get canSearchPlayers () {
    return this.vbl || this.isCollegeCoach
  }

  get data () {
    return this.roles.includes('data')
  }

  get memberRoute () {
    return this.member && {
      name: 'member-profile',
      params: {
        id: this.member.id,
        playerProfileId: this.member.playerProfileId,
        memberId: this.member.id,
        name: this.member.name,
        profilePic: this.member.profilePic
      }
    }
  }

  get sbAdmin () {
    return this.roles.filter(f => f.startsWith('sb-')).map(m => m.replace('sb-', ''))
  }

  get ntdp () {
    return this.pages.findIndex(f => f.id === 623) > -1
  }
}
