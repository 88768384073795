<template>
  <v-autocomplete
    v-model="iValue"
    :label="label"
    hint="Enter the name of your club"
    placeholder="Start typing to search"
    persistent-hint
    color="color3"
    autocomplete="off"
    :items="clubs"
    item-value="name"
    item-text="name"
    return-object
    :search-input.sync="term"
    :loading="searching"
    :error-messages="errorMessages"
    :clearable="!readonly"
    type="search"
    ref="input"
    :disabled="disabled"
    :readonly="readonly"
    @blur="$emit('blur')"

  >
    <template slot="no-data" v-if="allowAdd">
      <v-list-item @click.stop="$emit('manual-click', term)">
        <v-list-item-content>
          <v-list-item-title>
            Can't find the club?
          </v-list-item-title>
          <v-list-item-subtitle>
            We can not find a club matching "<strong>{{ term }}</strong>". Click here to enter it anyway.
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="data">
      <search-result-item
        :team="data.item"
        :showId="user && user.vblx"
      ></search-result-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'
import SearchResultItem from './SearchResultItem'

export default {
  props: ['disabled', 'allowAdd', 'value', 'label', 'errorMessages', 'readonly', 'returnObject'],
  data () {
    return {
      clubs: [],
      selectedResult: null,
      searchResults: [],
      term: null,
      searching: false,
      dupDialog: false,
      prevTerm: null,
      searched: false,
      newResults: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    searchHint () {
      return 'Enter the name of your club'
    },
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        if (this.returnObject && val) {
          this.$emit('input', val)
          return
        }
        const v = (val && val.name) || null
        this.$emit('input', v)
      }
    }
  },
  methods: {
    focus () {
      this.$refs.input.$el.focus()
    },
    getData () {
      this.searching = true
      this.$VBL.club.getAll()
        .then(r => { this.clubs = r.data.sort(firstBy('name')) })
        .catch(e => console.log(e))
        .finally(() => {
          this.searching = false
          this.searched = true
        })
    }
  },
  watch: {
  },
  components: {
    SearchResultItem
  },
  mounted () {
    this.getData()
  }
}
</script>

<style>

</style>
