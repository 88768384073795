// import endpoints from './endpoints'

export default class LiveScoringSDK {
  constructor (http) {
    this.http = http
  }

  start (dto) {
    return this.http.post('/matches/scoring/start', dto)
  }

  end (dto) {
    return this.http.post('/matches/scoring/end', dto)
  }

  keycheck (key, id) {
    return this.http.post(`/matches/scoring/keycheck?key=${key}&id=${id}`)
  }

  update (dto, key) {
    return this.http.post(`/matches/scoring/update?key=${key}`, dto)
  }
}
