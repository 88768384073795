<template>
  <v-card flat light>
    <loading-bar v-model="loading"></loading-bar>
    <!-- TITLE ROW -->
    <v-card-title class="title font-weight-regular justify-space-between" ref="topOfPage">
      <span v-if="currentStep">{{ currentStep.title }}</span>
      <v-avatar
        color="color3"
        class="color3Text--text"
        size="24"
        v-text="window + 1"
      ></v-avatar>
    </v-card-title>
    <!-- Alerts -->
    <v-card-text v-if="tournament.registartionAlerts && tournament.registartionAlerts.length">
      <v-alert
        v-for="alert in tournament.registartionAlerts"
        :key="alert.id"
        :type="alert.type"
        :value="true"
        :dismissible="alert.dismissable"
      >
        {{alert.text}}
      </v-alert>
    </v-card-text>
    <!-- Windows/Steps -->
    <v-card-text class="pt-0">
      <v-window v-model="window" touchless :key="steps.length">
        <v-window-item v-for="step in steps" :key="step.key" :value="step.value">

          <!-- Bids -->
          <v-card color="grey lighten-4" v-if="step.type === 'bid'">
            <bid-search
              :tournament="tournament"
              :registration="registration"
              :divisional="step.divisional"
              :optional="step.optional"
              :active="step.value === window"
              @bid-found="onNextClick"
            ></bid-search>
          </v-card>

          <!-- Division -->
          <v-card color="grey lighten-4" v-if="step.type === 'division'">
            <registration-division-list
              :tournament="tournament"
              :registration="registration"
              :adminQuickAdd="adminQuickAdd"
              :active="step.type === 'division' && active"
            ></registration-division-list>
          </v-card>

          <!-- Team Name -->
          <v-card color="grey lighten-4" v-if="step.type === 'teamName'">
            <v-card-text>
              <v-text-field
                name="teamName"
                label="Team Name*"
                id="teamName"
                v-model="registration.teamName"
                :hint="registration.teamName ? '' : 'Please enter a name for your team'"
                persistent-hint
                @blur="leadMe"
                color="color3"
              ></v-text-field>
            </v-card-text>
          </v-card>

          <!-- Club Info -->
          <v-card color="grey lighten-4" v-if="step.type === 'clubInfo'">
            <v-card-text>
              <program-form
                :question="false"
                title="Club Information"
                program="Club"
                :contacts="['Club Director']"
                :model="registration.club"
                :requiredFields="[['name', 'email', 'phone']]"
              ></program-form>
            </v-card-text>
          </v-card>

          <!-- Team Info -->
          <v-card color="grey lighten-4" v-if="step.type === 'teamInfo'">
            <v-card-text>
              <program-form
                :question="false"
                title="Team Information"
                program="Team"
                :contacts="['Team Admin']"
                :model="registration.teamInfo"
                :requiredFields="[['name', 'email', 'phone']]"
                :ibvl="registration.ibvl"
                :ncaa="registration.isNcaa"
                @name-change="registration.teamName = $event"
                :adminQuickAdd="adminQuickAdd"
              ></program-form>
            </v-card-text>
          </v-card>

          <!-- New Team Reg -->
          <v-card color="grey lighten-4" v-if="step.type === 'newTeamReg'">
            <v-card-text class="pt-0">
              <team-registration
                :model="registration.teamInfo"
                :teamType="tournament.teamType"
                :tournament="tournament"
                ref="newTeamReg"
                :active="active && step.type === 'newTeamReg'"
                @club-bid="onClubBid"
              ></team-registration>
            </v-card-text>
          </v-card>

          <!-- New Team Reg Step 2-->
          <v-card color="grey lighten-4" v-if="step.type === 'newTeamRegTeams'">
            <v-card-text>
              <teams-per-division
                :teamInfo="registration.teamInfo"
                ref="newTeamRegTeams"
                @valid-change="validTeams = $event"
                :active="currentStep && currentStep.type === 'newTeamRegTeams'"
                :clubBid="clubBid"
              ></teams-per-division>
            </v-card-text>
          </v-card>

          <!-- Players -->
          <v-card color="grey lighten-4" v-if="step.type === 'player'">
            <v-card-text class="pt-0">
                <registration-fields
                  v-if="step.player"
                  :tournament="tournament"
                  :registration="registration"
                  :player="step.player"
                  :adminQuickAdd="adminQuickAdd"
                  :playerI="step.i"
                  :active="step.value === window"
                  :partners="partnersFiltered"
                  :edit="edit"
                  :participantType="participantType"
                  @player-selected="enforceSameSchool"
                  @blur="leadMe"
                  @tbd-click="onTbdClick"
                  :allowZeroPlayers="registration.division.allowZeroPlayers"
                >
                </registration-fields>
            </v-card-text>
          </v-card>

          <!-- Additional Players -->
          <template v-if="step.type === 'alt'">
            <v-card color="grey lighten-4" v-if="registration.alternates.length === 0">
                <v-card-text class="title">
                  You are allowed to have up to {{registration.division.numAllowedOnRoster}} players on the roster.
                </v-card-text>
                <v-card-text class="subheading">
                  Would you like to add an additional player?
                </v-card-text>
                <v-card-actions>
                  <v-btn color="success" @click.native="addPlayer" >Yes</v-btn>
                  <v-btn color="error" @click.native="onNextClick" >No</v-btn>
                </v-card-actions>
            </v-card>
            <template v-else>
              <v-card
                color="grey lighten-4"
                class="mb-2"
                v-for="(player, i) in registration.alternates"
                :key="registration.division.numOfPlayers + i"
              >
                <v-card-text class="py-0">
                  <registration-fields
                    :tournament="tournament"
                    :registration="registration"
                    :player="player"
                    :adminQuickAdd="adminQuickAdd"
                    :playerI="registration.division.numOfPlayers + i + 1"
                    :active="step.value === window"
                    :partners="partnersFiltered"
                    :edit="edit"
                    :participantType="participantType"
                    @player-selected="enforceSameSchool"
                    @blur="leadMe"
                    :noTbd="true"
                  >
                  </registration-fields>
                </v-card-text>
                <v-card-actions class='pt-0'>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="white color3--text"
                    fab
                    small
                    @click.stop="registration.dropPlayer(registration.division.numOfPlayers + i)"
                  >
                    <v-icon>fas fa-trash</v-icon>
                  </v-btn>

                </v-card-actions>
              </v-card>
              <v-btn
                color="color3"
                v-if="registration.alternates.length > 0 && !registration.rosterFull"
                text
                @click.native="addPlayer"
              >add another</v-btn>
            </template>
          </template>

          <!-- Rumble Field Choice -->
          <template v-if="step.type === 'field'">
            <v-card color="grey lighten-4" >
              <v-card-text class="title">
                If you have a field preference please update the below selections
              </v-card-text>
              <v-card-text class="subheading">
                <rumble-field-choice
                  :tournament="tournament"
                  :registration="registration"
                  :active="step.value === window"
                ></rumble-field-choice>
              </v-card-text>
            </v-card>
          </template>

          <!-- Additional Fields -->
          <v-card color="grey lighten-4" v-if="step.type === 'add-fields'">
            <v-card-text>
              <v-row>
                <!-- Additional Fields -->
                <additional-field
                  v-for="(field, i) in additionalFields" :key="`${field.label}-${i}`"
                  :registration="registration"
                  :field="field"
                  :fieldI="i"
                  @valid-change="onAdditionValidChange"
                  :solo="true"
                ></additional-field>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Review -->
          <template v-if="step.type === 'review'">
            <!-- NEW TEAM REG -->
            <v-card color="grey lighten-4" v-if="newTeamReg">
              <team-registration-review
                :id="`${checks}-team-review`"
                :loading="checking"
                :registration="registration"
                :active="step.type === 'review'"
                @agree-change="refundAgreed = $event"
                ref="newTeamRegReview"
              ></team-registration-review>
            </v-card>

            <!-- OG REVIEW -->
            <v-card color="grey lighten-4" v-else>
              <!-- Team Name -->
              <template v-if="registration.teamName">
                <v-toolbar dense color="color2 color2Text--text">
                  <v-toolbar-title>
                    Team
                  </v-toolbar-title>
                </v-toolbar>
                <v-container fluid class="hidden-sm-and-up">
                  <v-row dense>
                    <v-col cols="12">
                      <strong>Name: </strong>{{registration.teamName}}
                    </v-col>
                  </v-row>
                </v-container>
                <v-container fluid class="hidden-xs-only">
                  <v-row dense>
                    <v-col class="text-right" cols="2" >
                      <strong>Name:</strong>
                    </v-col>
                    <v-col cols="10">
                      {{registration.teamName}}
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <field-display
                v-if="isClubVClub"
                :id="`${checks}-cvc`"
                :loading="checking"
                title="Club Information"
                :fields="extraFields"
                bgColor="grey lighten-4"
              ></field-display>
              <field-display
                v-if="teamReg"
                :id="`${checks}-team`"
                :loading="checking"
                title="Team Information"
                :fields="extraFields"
                bgColor="grey lighten-4"
              ></field-display>

              <!-- Players Review -->
              <template v-for="(player,i) in registration.players.filter(f => f.firstName)">
                <registration-fields-review
                  v-if="registration.division"
                  :key="i"
                  :fields="fields"
                  :requiredFields="requiredFields"
                  :player="player"
                  :sanctioningBodyId="registration.division.sanctioningBodyId"
                  :i="i+1"
                  :title="`${participantType} ${registration.players.length > 1 ? i+1 : ''}`"
                  bgColor="grey lighten-4"
                  :adds="additionalFieldsToDisplay"
                  :registration="registration"
                  :tournament="tournament"
                >
                </registration-fields-review>
              </template>
              <field-display
                :id="checks"
                :loading="checking"
                title="Registration"
                :fields="regFields"
                bgColor="grey lighten-4"
              ></field-display>
            </v-card>
            <!-- DEPOSITS -->
            <div class="subheading ml-4" v-if="showMinOption">
              <v-checkbox
                :label="registration.window.minAmountLabel"
                v-model="registration.minAmount"
                color="success"
              ></v-checkbox>
              <v-expand-transition>
                <div v-if="registration.minAmount && registration.window.minAmountAgreement">
                  <v-divider></v-divider>
                  <v-checkbox
                    :label="registration.window.minAmountAgreement"
                    v-model="minAgree"
                    color="success"
                  ></v-checkbox>
                </div>
              </v-expand-transition>
            </div>
            <!-- ALL INFO ACCURATE -->
            <v-checkbox
              color="success"
              label="All information is accurate"
              v-model="registration.confirmed"
            ></v-checkbox>
            <!-- WAIVERS -->
            <template v-for="w in waivers">
              <v-card :key="`div-${w.id}`" color="grey lighten-4">
                <v-toolbar color="color2 color2Text--text" dense>
                  <v-toolbar-title>{{ w.name }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="body-1">
                  <div v-if="w.instructions" v-html="w.instructions"></div>
                  <div v-else>All participants are required to read and agree to: <a :href="w.url" target="_blank" class="color3--text" style="text-decoration: none">{{w.name}}</a></div>
                  <v-checkbox
                    v-model="agreed"
                    :key="w.label"
                    :value="w.url"
                    :label="w.laabel || `I have read and agree to the ${w.name}`"
                    hide-details
                    color="success"
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </template>
            <!-- REFUND POLICY -->
            <v-expand-transition>
              <refund-policy-display
                v-if="registration.division && registration.division.refundPolicyId && !newTeamReg"
                :refundPolicyId="registration.division.refundPolicyId"
                :active="step.type === 'review'"
                @agree-change="refundAgreed = $event"
              >
              </refund-policy-display>
            </v-expand-transition>
          </template>

        </v-window-item>
      </v-window>
    </v-card-text>
    <v-divider></v-divider>
    <!-- Buttons -->
    <v-card-actions>
      <!-- BACK -->
      <v-fab-transition>
        <v-btn
          v-if="previousStep"
          color="color3Text color3--text"
          fab
          @click="onBackClick"
        ><v-icon>fas fa-caret-left</v-icon></v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          v-if="previousStep"
          color="color3"
          text
          @click="onBackClick"
        >Back</v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <!-- NEXT -->
      <v-fab-transition>
        <v-btn
          v-if="nextBtn && nextBtn.show"
          color="color3"
          text
          :disabled="nextBtn.disabled"
          @click="nextBtn.onClick"
        >{{nextBtn.text}}</v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          v-if="nextBtn && nextBtn.show"
          color="color3 color3Text--text"
          fab
          :disabled="nextBtn.disabled"
          :loading="nextBtn.loading"
          @click="nextBtn.onClick"
        ><v-icon>{{nextBtn.icon}}</v-icon></v-btn>
      </v-fab-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import RegistrationFields from './NewRegistrationFields.vue'
import RegistrationFieldsReview from '@/components/Tournament/Registration/RegistrationFieldsReview'
import BidSearch from './BidSearch'
import RegistrationDivisionList from './RegistrationDivisionList'
import { mapGetters } from 'vuex'
import { usDollars, formatArray } from '@/Filters'
import FieldDisplay from './FieldDisplay'
import CartItem from '@/classes/CartItem'
import isVisible from '@/helpers/IsVisible'
import ProgramForm from '@/components/Forms/ProgramForm'
import max from 'lodash.max'
const RumbleFieldChoice = () => import('@/components/Tournament/Registration/New/RumbleFieldChoice.vue')
const RefundPolicyDisplay = () => import('@/components/Cart/RefundPolicyDisplay.vue')
const TeamRegistration = () => import('@/components/Tournament/Registration/New/Teams/TeamSelection.vue')
const TeamsPerDivision = () => import('@/components/Tournament/Registration/New/Teams/TeamsPerDivision.vue')
const TeamRegistrationReview = () => import('@/components/Tournament/Registration/New/Teams/TeamRegistrationReview.vue')
const AdditionalField = () => import('@/components/Tournament/Registration/New/AdditionalField')

export default {
  props: ['tournament', 'registration', 'adminQuickAdd', 'edit', 'quickAddLoading', 'active', 'leadId', 'stepHistory'],
  data () {
    return {
      loading: false,
      window: 0,
      partners: [],
      agreed: [],
      unlimitedResults: {},
      checks: 0,
      checking: false,
      refundAgreed: false,
      validTeams: false,
      additionalFieldsValidation: [],
      minAgree: false,
      clubBid: null
    }
  },
  computed: {
    ...mapGetters(['user', 'admin', 'liveUpdates']),
    additionalFields () {
      if (this.registration && this.registration.division && this.tournament) {
        const fields = this.tournament.getCustomFields(this.registration.division.id)
        return fields ? fields.filter(f => !f.perPlayer) : []
      }
      return []
    },
    additionalFieldsValid () {
      return this.additionalFieldsValidation.length === this.additionalFields.length
    },
    aStarters () {
      return this.registration.starters
    },
    aAlts () {
      return this.registration.alternates
    },
    isCoaches () {
      return this.registration && this.registration.division && this.registration.division.isCoaches
    },
    coachProps () {
      return this.registration && this.registration.division && this.registration.division.coachProps
    },
    participantType () {
      return this.isCoaches ? 'Coach' : this.tournament.participantType === 'Team' ? 'Player' : this.tournament.participantType
    },
    agreedToAll () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return !this.waivers || JSON.stringify(this.agreed.sort()) === JSON.stringify(this.waivers.map(m => m.url || m.id).sort())
    },
    refundAgree () {
      if (this.newTeamReg || (this.registration.division && this.registration.division.refundPolicyId)) return this.refundAgreed
      return true
    },
    waivers () {
      return this.registration &&
        this.registration.division &&
        this.tournament.jProps &&
        this.tournament.jProps.waivers &&
        this.tournament.jProps.waivers.filter(f => f.divisions.includes('*') || f.divisions.includes(this.registration.division.id))
    },
    askForPartner () {
      return this.individual && this.tournament.props.includes('partner')
    },
    individual () {
      return this.registration && this.registration.division && this.registration.division.numOfPlayers === 1 && this.registration.division.numAllowedOnRoster === 1
    },
    additionalFieldsToDisplay () {
      return [
        { label: 'Partner', value: this.registration.partner || 'NA', show: this.askForPartner },
        { label: 'Team', value: formatArray((this.registration.teams || []).map(m => m.name)), show: this.isCoaches && this.coachProps && this.coachProps.team }
      ].filter(f => f.show)
    },
    extraFields () {
      const f = []
      const ef = this.registration.extraFields
      for (const prop in ef) {
        const l = prop.startsWith('~') ? prop.substring(1) : prop
        f.push(
          { label: l, value: ef[prop], show: true }
        )
      }
      return f
    },
    extraFieldsPerTeam () {
      const r = []
      if (this.registration && this.registration.division && this.tournament) {
        const fields = this.tournament.getCustomFields(this.registration.division.id)
        if (fields && fields.length > 0) {
          fields.filter(f => !f.perPlayer).forEach(field => {
            const l = `${field.label}`
            r.push({ label: l, value: this.registration.getAddField(l), show: true })
          })
        }
      }
      return r
    },
    regFields () {
      var fields = [
        { label: 'Tournament', value: this.tournament.name, show: true },
        { label: 'Date', value: this.registration.division && this.registration.division.startDate.format('dddd, MMMM Do YYYY'), show: true },
        { label: 'Division', value: this.registration.division && this.registration.division.divisionsString, show: true },
        { label: 'Registration Option', value: this.registration.window && this.registration.window.name, show: this.registration.division && this.registration.division.currentWindows.length > 1 },
        ...this.extraFieldsPerTeam,
        { label: 'Entry Fee', value: usDollars(this.entryFee), show: true },
        { label: 'Bid Discount', value: `-${usDollars(this.bidDiscount)}`, show: !!this.bidDiscount },
        { label: 'Amount', value: `${usDollars(this.minAmountDue)}`, show: this.registration.minAmount },
        ...this.unlimitedDiscounts(),
        { label: 'Amount Due Today', value: usDollars(this.total), show: this.total !== this.entryFee }
      ]

      return fields.filter(f => f.show)
    },
    steps () {
      let s = 1
      const doubles = this.registration && this.registration.division && this.registration.division.numOfPlayers === 2
      const kob = this.registration && this.registration.division && this.registration.division.numOfPlayers === 1
      const tBid = this.tournament.bidProgram
      const rBid = this.registration.division && this.registration.division.bidProgram
      const skipBid = this.tournament.props && this.tournament.props.includes('skipBid')
      let steps = [
        {
          value: s++,
          type: 'bid',
          key: 'tournament-bid',
          title: 'Bid Number',
          valid: this.registration.bid || !tBid || !tBid.required,
          skip: !tBid || skipBid,
          divisional: false,
          optional: !tBid || !tBid.required
        },
        {
          value: s++,
          type: 'division',
          key: 'divisions',
          title: 'Division',
          valid: this.registration.division && this.registration.window,
          skip: this.tournament.allOrgRegFlow && this.newTeamReg
        },
        {
          value: s++,
          type: 'bid',
          key: 'division-bid',
          title: 'Bid Number',
          valid: this.registration.bid || !rBid || !rBid.required,
          skip: !rBid || skipBid,
          divisional: true,
          optional: !rBid || !rBid.required
        },
        {
          value: s++,
          key: 'club-info',
          type: 'clubInfo',
          title: 'Club Information',
          valid: this.registration.club.valid,
          skip: this.newTeamReg || !this.isClubVClub
        },
        {
          value: s++,
          key: 'team-info',
          type: 'teamInfo',
          title: 'Team Information',
          valid: this.registration.teamInfo.valid,
          skip: !this.teamReg || this.newTeamReg
        },
        {
          value: s++,
          key: 'new-team-reg',
          type: 'newTeamReg',
          title: `${this.tournament.teamType === 'club' ? 'Club' : this.tournament.teamType === 'hs' ? 'School' : 'Team'} Information`,
          valid: this.registration.teamInfo.valid,
          skip: !this.newTeamReg
        },
        {
          value: s++,
          key: 'new-team-reg-teams',
          type: 'newTeamRegTeams',
          title: 'Teams & Divisions',
          valid: this.validTeams,
          skip: !this.newTeamReg
        },
        {
          value: s++,
          key: 'team-name',
          type: 'teamName',
          title: 'Team Name',
          valid: this.registration.teamName,
          skip: ((doubles || kob) && !this.useTeamNames) || this.teamReg || this.noTeamNames || this.newTeamReg
        }
      ]
      let i = 1
      this.registration.starters.forEach(p => {
        steps.push({
          value: s++,
          key: `player-${i}`,
          type: 'player',
          title: i === 1 && !doubles && !this.isClubVClub && !this.teamReg ? kob ? `${this.participantType} Information` : 'Team Captain' : 'Players',
          valid: p.valid,
          skip: this.newTeamReg,
          player: p,
          i: i++
        })
      })
      steps.push({
        value: s++,
        key: 'alts',
        type: 'alt',
        title: 'Alternates',
        valid: !this.inValidAlternate,
        skip: !this.allowAlternates || this.newTeamReg,
        skipBack: !this.registration.hasAlternates
      })
      if (this.rumbleFields && this.rumbleFields.length > 1) {
        steps.push({
          value: s++,
          key: 'field',
          type: 'field',
          title: 'Field Preference',
          valid: true,
          skip: false
        })
      }
      if (this.additionalFields && this.additionalFields.length > 0) {
        steps.push({
          value: s++,
          key: 'add-fields',
          type: 'add-fields',
          title: 'Additional Information',
          valid: this.additionalFieldsValid,
          skip: false
        })
      }
      steps.push({
        value: s++,
        key: 'review',
        type: 'review',
        title: 'Review',
        valid: this.registration.confirmed && this.agreedToAll && this.refundAgree && this.validMinOption,
        skip: false,
        complete: true
      })

      s = 0
      steps = steps.filter(f => !f.skip)
      steps.forEach(f => { f.value = s++ })

      return steps
    },
    isClubVClub () {
      return this.registration && this.registration.division && this.registration.division.isClubVClub
    },
    teamReg () {
      return this.registration && this.registration.division && this.registration.division.teamReg
    },
    newTeamReg () {
      return (this.tournament.allOrgRegFlow || (this.registration && this.registration.division && (this.registration.division.isNcaa || this.registration.division.isClubVClub)))
    },
    useTeamNames () {
      return this.registration && this.registration.division && this.registration.division.useTeamNames
    },
    noTeamNames () {
      return this.registration && this.registration.division && this.registration.division.noTeamNames
    },
    allowAlternates () {
      return (this.registration.division && this.registration.division.numOfPlayers !== this.registration.division.numAllowedOnRoster && this.registration.players.length === this.registration.division.numOfPlayers) || this.registration.alternates.length > 0
    },
    inValidAlternate () {
      return this.allowAlternates && this.registration.alternates.filter(f => !f.valid).length > 0
    },
    currentStep () {
      return this.steps.find(f => f.value === this.window)
    },
    currentI () {
      return this.steps.findIndex(f => f.value === this.window)
    },
    nextStep () {
      return this.steps[this.currentI + 1]
    },
    reviewStep () {
      return this.steps.find(f => f.type === 'review')
    },
    rumbleFieldStep () {
      return this.steps.find(f => f.type === 'field')
    },
    additionalFieldsStep () {
      return this.steps.find(f => f.type === 'add-fields')
    },
    nextBtn () {
      return this.currentStep ? {
        show: this.nextStep || this.currentStep.complete,
        disabled: !this.currentStep.valid,
        onClick: this.currentStep.complete ? this.onCompleteClick : this.onNextClick,
        text: this.currentStep.complete ? this.adminQuickAdd ? 'Add Team' : this.edit ? 'Save Changes' : 'Add To Cart' : 'Next',
        icon: this.currentStep.complete ? this.adminQuickAdd ? 'fas fa-plus' : this.edit ? 'fas fa-save' : 'fas fa-shopping-cart' : 'fas fa-caret-right',
        loading: this.adminQuickAdd ? this.quickAddLoading : false
      } : null
    },
    previousStep () {
      const vSteps = this.steps.map(m => m.valid && !m.skipBack ? m.value : 1).filter(f => f < this.currentI)
      return this.steps[max(vSteps)]
    },
    bid () {
      return this.registration.bid
    },
    bidDiscount () {
      if (this.bid && (this.bid.discountAmount || this.bid.discountPercentage)) {
        return this.bid.discountAmount || this.entryFee * this.bid.discountPercentage
      }
      return 0
    },
    entryFee () {
      const w = this.registration.window
      if (w && w.fee) {
        if (w.feeIsPerTeam) {
          return w.fee
        } else {
          const p = w.truePerPlayer ? this.registration.completePlayers.length : this.registration.players.length
          return w.fee * p
        }
      }
      return 0
    },
    total () {
      if (this.registration.minAmount) return this.minAmountDue
      var t = (this.entryFee - this.bidDiscount - this.unlimitedDiscount())
      return t > 0 ? t : 0
    },
    playerOneId () {
      return this.registration && this.registration.players.length && this.registration.players[0] ? this.registration.players[0].playerProfileId : 0
    },
    seasonPassId () {
      const pass = this.tournament.props.find(f => f.startsWith('seasonPass'))
      if (pass) {
        const p = pass.split('-')
        return +p[1]
      }
      return false
    },
    playerProfileIds () {
      return this.registration && this.registration.players.map(p => p.playerProfileId || false).filter(f => !!f)
    },
    partnersFiltered () {
      return this.partners && this.playerProfileIds ? this.partners.filter(f => !this.playerProfileIds.includes(f.id)) : this.partners
    },
    fields () {
      const d = this.registration.division
      const a = d && d.registrationFields ? d.registrationFields.fields : []
      return (d && d.gender && d.gender.name !== 'Coed') ? a.filter(f => f !== 'gender') : a
    },
    requiredFields () {
      const d = this.registration.division
      const a = d && d.registrationFields ? d.registrationFields.requiredFields : []
      return (d && d.gender && d.gender.name !== 'Coed') ? a.filter(f => f !== 'gender') : a
    },
    rumbleFields () {
      return this.registration.division && this.tournament.props.includes('fields') && this.tournament.jProps && this.tournament.jProps.fields && this.tournament.jProps.fields.filter(f => f.divisionIds.includes(this.registration.division.id))
    },
    showMinOption () {
      const r = this.registration
      const w = r && r.window
      return w && w.minAmountDue !== null && w.minAmountDue !== w.entryFee
    },
    minAmountDue () {
      if (!this.showMinOption) return false
      return this.registration.minAmount && this.registration.window.minAmountDue
    },
    validMinOption () {
      if (!this.showMinOption) return true
      if (this.registration.minAmout) {
        return !this.registration.window.minAmountAgreement || this.minAgree
      }
      return true
    }
  },
  methods: {
    onTbdClick () {
      this.onNextClick('tbd')
    },
    checkV (force) {
      const v = this.$refs.topOfPage && isVisible(this.$refs.topOfPage)
      if (force || !v) {
        this.$vuetify.goTo(this.$refs.topOfPage, { offset: 50 })
      }
    },
    unlimitedDiscounts () {
      if (this.seasonPassId && this.checks) {
        // const w = this.registration.window
        // const playersN = this.registration.players.length
        // const feePer = w.feeIsPerTeam ? w.fee / playersN : w.fee
        return this.playerProfileIds.map(p => {
          const pStr = `${p}`
          if (!this.unlimitedResults[pStr]) return { show: false, val: 0 }
          const player = this.registration.players.find(f => f.playerProfileId === p)
          return {
            label: 'Discount',
            value: `$10  ${player.fullname}'s Member Discount`,
            val: 10,
            show: true
          }
        })
      }
      return []
    },
    unlimitedDiscount () {
      return this.unlimitedDiscounts().map(m => m.val).reduce((a, b) => a + b, 0)
    },
    cartItem (r) {
      r = r || this.registration
      const item = new CartItem()
      item.type = 'TR'
      item.organization = r.organization
      item.name = this.bidDiscount ? 'Tournament Registration with Bid' : 'Tournament Registration'
      item.description = `${this.tournament.name} | ${r._teamName} | ${r.division.divisionsString}${r.window.name ? ` | ${r.window.name}` : ''}`
      item.amount = this.total
      item.registration = r.dto
      item.leadId = this.leadId
      item.stepHistory = this.stepHistory
      item.product = JSON.stringify({ unlimitedDiscounts: this.unlimitedDiscounts() })
      item.division = r.division.divisionsString
      item.teamName = r._teamName
      item.preAuth = r.window.canPayAtEvent
      item.cartFees.push(...this.tournament.getCartFeesForReg(r))
      item.refundPolicyId = r.division.refundPolicyId
      return item
    },
    onBackClick () {
      this.registration.confirmed = false
      this.window = this.previousStep.value
    },
    onNextClick (from) {
      this.checkUnlimited()
      this.enforceSameSchool()
      this.window = from === 'tbd' ? this.rumbleFieldStep ? this.rumbleFieldStep.value : this.additionalFieldsStep ? this.additionalFieldsStep.value : this.reviewStep.value : this.nextStep.value
      if (!this.edit && !this.adminQuickAdd) this.checkV()
    },
    getNewTeamRegCartItems () {
      // const a = this.$refs.newTeamRegReview && this.$refs.newTeamRegReview[0]
      // const b = a && a.registrations && a.registrations.map(m => this.cartItem(m))

      const r = this.registration.teamInfo
      const c = r && r.contacts && r.contacts[0]
      const d = r.teams.map(t => {
        const r = this.tournament.newRegistration(t.divisionId)
        r.teamName = t.name
        r.teamId = t.id
        r.teamInfo.contacts.push(c)
        return r
      }).map(m => this.cartItem(m))
      return d
    },
    onCompleteClick () {
      const dto = this.newTeamReg ? this.getNewTeamRegCartItems() : this.cartItem()
      this.$emit('complete-click', dto)
    },
    enforceSameSchool () {
      if (this.registration.division &&
        this.registration.division.registrationFields.requiredFields.includes('sameSchool') &&
        this.registration.players.length > 1 &&
        this.registration.players[0].highSchool) {
        this.registration.players.forEach(p => {
          p.highSchool = this.registration.players[0].highSchool
        })
      }
    },
    addPlayer () {
      this.registration.addPlayer()
    },
    reset () {
      this.window = 0
      if (this.$refs.newTeamReg) this.$refs.newTeamReg[0].reset()
      if (this.$refs.newTeamRegTeams) this.$refs.newTeamRegTeams[0].reset()
      this.clubBid = null
    },
    leadMe () {
      this.$emit('lead-me', this.cartItem())
    },
    getPartners () {
      if (!this.playerOneId) return
      const dto = {
        playerId: this.playerOneId,
        membersOnly: this.tournament.membersOnly,
        tournamentId: this.tournament.id
      }
      this.$VBL.player.partners(dto)
        .then(r => {
          this.partners = r.data || []
        })
        .catch(e => console.log(e))
        .finally(() => { this.searching = false })
    },
    onAdditionValidChange (v) {
      if (v.valid) {
        if (!this.additionalFieldsValidation.includes(v.i)) this.additionalFieldsValidation.push(v.i)
      } else {
        this.additionalFieldsValidation = this.additionalFieldsValidation.filter(f => f !== v.i)
      }
    },
    checkUnlimited () {
      this.checks++
      if (this.seasonPassId && this.playerProfileIds && this.playerProfileIds.length) {
        this.playerProfileIds.forEach(n => {
          const a = `${n}`
          if (!(a in this.unlimitedResults)) {
            this.checking = true
            this.$VBL.player.hasSeasonPass(this.seasonPassId, n)
              .then(r => { this.unlimitedResults[a] = r.data })
              .finally(() => {
                this.checks++
                this.checking = false
              })
          }
        })
      }
    },
    onClubBid (v) {
      this.clubBid = v
    }
  },
  watch: {
    window: function (val) {
      this.$emit('step-change', this.currentStep.type)
    },
    playerOneId: 'getPartners',
    'registration.club.name': function (val) {
      this.registration.teamName = val
    }
  },
  components: {
    RegistrationFields,
    RegistrationFieldsReview,
    BidSearch,
    RegistrationDivisionList,
    FieldDisplay,
    ProgramForm,
    RumbleFieldChoice,
    RefundPolicyDisplay,
    TeamRegistration,
    TeamsPerDivision,
    TeamRegistrationReview,
    AdditionalField
  }
}
</script>

<style>
.bt {
  border-top: 2px solid black;
}
</style>
