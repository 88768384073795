import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { store } from '@/store'
import vuetify from '@/plugins/vuetify'
import Vuelidate from 'vuelidate'
import vbl from '@/VBL/endpoints'
import VueAxios from 'vue-axios'
import axios from 'axios'
import AuthService from '@/Oidc/AuthService'
import VueAnalytics from 'vue-analytics'
import VueGtag from 'vue-gtag'
import * as filters from '@/Filters'
import LoadingBar from '@/components/Utils/LoadingBar.vue'
import Blocked from '@/components/Utils/Blocked.vue'
import VBLSDKPlugin from '@/plugins/VBLSDKPlugin'
import MessageCard from '@/components/Utils/MessageCard.vue'
import StatusButton from '@/components/Utils/StatusButton.vue'
import VblBreadcrumbs from '@/components/Utils/VblBreadcrumbs.vue'
import ConfirmDialog from '@/components/Utils/ConfirmDialog.vue'
import FitText from '@/components/Utils/FitText.vue'
import VueHtml2Canvas from 'vue-html2canvas'
import VueYouTubeEmbed from 'vue-youtube-embed'
import BidStream from '@/New/BidStream'
// import VueMouseflow from 'vue-mouseflow'
import * as actions from '@/store/ActionTypes'
import * as mutations from '@/store/MutationTypes'
Vue.config.productionTip = false
// Validation
Vue.use(Vuelidate)

// Http/Ajax
const auth = new AuthService()

Vue.use(VueAxios, axios)
axios.defaults.baseURL = vbl.baseURL
axios.interceptors.request.use(async (config) => {
  const token = await auth.getAccessToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
},
(err) => {
  // What do you want to do when a call fails?
  console.log(err)
})
// axios.interceptors.response.use(undefined, e => {
//   let r = e.response
//   if (r && r.status === 401) {
//     console.log('unauthorized')
//   }
// })

// GOOGLE ANALYTICS
const gaId = process.env.NODE_ENV === 'production' ? 'UA-114195278-1' : 'UA-114195278-2'
Vue.use(VueAnalytics, {
  id: gaId,
  router
})
const gtagId = process.env.NODE_ENV === 'production' ? 'G-EFQJ281GE7' : 'G-CR22PHJB07'
Vue.use(VueGtag, {
  config: { id: gtagId }
}, router)

// MOUSE FLOW
// const mfId = 'e29420a2-bf4a-466b-a941-9d878186c12a'
// Vue.use(VueMouseflow, {
//   tracking_key: mfId
// })

// FILTERS
Vue.filter('usDollars', filters.usDollars)
Vue.filter('usPhone', filters.usPhone)
Vue.filter('ordinal', filters.ordinal)
Vue.filter('ordinalSuffix', filters.ordinalSuffix)
Vue.filter('localDt', filters.localDt)
Vue.filter('localDate', filters.localDate)
Vue.filter('readableDate', filters.readableDate)
Vue.filter('readableDateRange', filters.readableDateRange)
Vue.filter('time', filters.time)
Vue.filter('shortDate', filters.shortDate)
Vue.filter('formatArray', filters.formatArray)
Vue.filter('formatArraySorted', filters.formatArraySorted)
Vue.filter('fixed1', filters.fixed1)
Vue.filter('fixed2', filters.fixed2)
Vue.filter('fixed2Up', filters.fixed2Up)
Vue.filter('fixed3', filters.fixed3)
Vue.filter('twoDigits', filters.twoDigits)
Vue.filter('ageDivision', filters.ageDivision)
Vue.filter('ageDivisionYouth', filters.ageDivisionYouth)
Vue.filter('intelliFormat', filters.intelliFormat)
Vue.filter('pipeArray', filters.pipeArray)
Vue.filter('slashArray', filters.slashArray)
Vue.filter('fixed1OrInt', filters.fixed1OrInt)
Vue.filter('pointArray', filters.pointArray)
Vue.filter('bestFinish', filters.bestFinish)
Vue.filter('version', filters.version)
Vue.filter('singles', filters.singles)
Vue.filter('tens', filters.tens)
Vue.filter('capFirst', filters.capFirst)
Vue.filter('formatArrayOr', filters.formatArrayOr)
Vue.filter('unixToLocalDt', filters.unixToLocalDt)
Vue.filter('formatArraySort', filters.formatArraySort)
Vue.filter('trim100', filters.trim100)
Vue.filter('trim50', filters.trim50)
Vue.filter('ratingDisplay', filters.ratingDisplay)
Vue.filter('ratingAge', filters.ratingAge)
Vue.filter('ratingDisplayAlpha', filters.ratingDisplayAlpha)
Vue.filter('time24To12', filters.time24To12)

// COMPONENTS
Vue.component('loading-bar', LoadingBar)
Vue.component('got-blocked', Blocked)
Vue.component('message-card', MessageCard)
Vue.component('status-button', StatusButton)
Vue.component('vbl-breadcrumbs', VblBreadcrumbs)
Vue.component('vbl-confirm-dialog', ConfirmDialog)
Vue.component('fit-text', FitText)
Vue.component('BidStream', BidStream)
Vue.use(VueHtml2Canvas)
Vue.use(VueYouTubeEmbed)

// PLUGINS
Vue.use(VBLSDKPlugin)

const el = document.getElementById('sub')
const username = el && el.value && el.value !== '{sub}' ? el.value : null

store.dispatch(actions.LOAD_THEME, username)
  .then(username => {
    store.commit(mutations.APPLY_THEME, username)
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })
